"use strict";

import {translate} from '@elements/translations';

export function initInScope ($scope) {
    /* description:
    (?=.*[a-z]) - must contain 1 lowercase alphabetical character
    (?=.*[A-Z]) - must contain 1 uppercase alphabetical character
    (?=.*[0-9]) - must contain 1 numeric character
    (?=.*[!@#$%^&*]) - must contain 1 special character
    (?=.{8,}) - must be eight characters or longer
*/
    let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    let mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    $scope.find('.js-password-strength__input').bind('propertychange change keyup input paste', function () {
        let value = $(this).val(),
            $container = $(this).closest('.js-password-strength');

        $container.removeClass('is-medium is-low is-strong');
        if(value.length !== 0) {
            if(strongRegex.test(value)) {
                $container.addClass('is-strong');
                $container.find('.js-password-strength__text').text(translate('password.strong'));
            } else if(mediumRegex.test(value)) {
                $container.find('.js-password-strength__text').text(translate('password.medium'));
                $container.addClass('is-medium');
            } else {
                $container.find('.js-password-strength__text').text(translate('password.low'));
                $container.addClass('is-low');
            }
        }
    });
}