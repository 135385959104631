'use strict';
import 'url-polyfill';
import 'url-search-params-polyfill'; // Edge Polyfill
import fetch from '@elements/fetch'; // IE10 Polyfill
import initModulesInScope from "@elements/init-modules-in-scope";
import {isParsleyForm, loadParsley} from "@elements/parsley-bootstrap-validation";
import {clearAll, showNotification} from "@elements/alert-notification";
import loadScript from '@elements/load-script';
import formDataEntries from "form-data-entries";
import {responseTracking} from "@elements/tracking";
import "friendly-challenge/widget";

export function initInScope($scope) {

    let $ajaxCheckForms = $scope.find('.js-ajax-check');

    $ajaxCheckForms.each(function () {
        let $form = $(this);
        let modalSelectorSm = $form.data('ajax-check-modal-sm');
        let modalSelectorMd = $form.data('ajax-check-modal-md');
        let modalSelectorLg = $form.data('ajax-check-modal-lg');
        let modals = {
            'sm': $(modalSelectorSm),
            'md': $(modalSelectorMd),
            'lg': $(modalSelectorLg)
        };
        let url; // = $form.attr('action');
        let $notificationsContainer = $form.find('.js-ajax-check__errors');
        let method = $form.data('method') || $form.attr('method') || 'POST';

        const $loading = $form.find('.js-ajax-check__loading');

        let pendingRequest;

        // get all Submit-Buttons of the form
        let $submitButtons = $($form[0].elements).filter(':submit');
        let $clickedSubmitButton;

        // save the clicked submit-button to use its attributes on submission
        $submitButtons.on('click', function () {
            $clickedSubmitButton = $(this);
        });

        $('body').on('click', `.js-ajax-check__btn[form=${$form.attr('name')}]`, function (e) {
            $clickedSubmitButton = $(this);
            //handleSubmission();
            $form.trigger('submit', ['submit']);
        });

        $form.on('forceSubmission', function (e) {
            handleSubmission();
        });

        $form.on('submit', function(e, forceSubmit) {
            e.preventDefault();

            if (typeof e.originalEvent === 'undefined' && !forceSubmit) {
                // prevent parsley from firing twice submit twice (due to promise validator)
                return false;
            }

            if (isParsleyForm($form)) {
                loadParsley().then(function () {
                    if ($form.data('Parsley')) {
                        $form.data('Parsley').whenValid().then(() => {
                            handleSubmission();
                        })
                    }
                });
            } else {
                handleSubmission();
            }
        });

        function handleSubmission() {
            $loading.attr('hidden', false);
            if(pendingRequest) {
                pendingRequest.abort();
                pendingRequest = null;
            }

            const data = formDataEntries($form[0]);

            if ($clickedSubmitButton && $clickedSubmitButton.length) {
                // add name and value of the clicked button to the formdata
                let submitButtonName = $clickedSubmitButton.attr('name');
                if (submitButtonName) {
                    data.push([$clickedSubmitButton.attr('name'), $clickedSubmitButton.val()]);
                }

                // if the clicked submit button has a formaction, use it as url for the request
                let formAction = $clickedSubmitButton.attr('formaction');
                if (formAction) {
                    url = formAction;
                } else {
                    url = $form.attr('action');
                }

                $clickedSubmitButton.attr('disabled', 'disabled');
            } else {
                url = $form.attr('action');
            }
            let params = new URLSearchParams(data);

            // push data to the datalayer
            let trackingKey = $form.data('ajax-check-tracking-key');
            if (trackingKey) {
                if (_config[trackingKey]) {
                    // the tracking-key defines which value from the config will be pushed
                    let trackingData = _config[trackingKey];

                    window.dataLayer = window['dataLayer'] || [];

                    // the tracking data for add_payment_info needs the payment type
                    if ($form.hasClass('js-ajax-check--payment')) {
                        let paymentType = $(".js-ajax-check__payment-type:checked").val();
                        trackingData.ecommerce = {...trackingData.ecommerce, 'payment_type': paymentType};
                    }

                    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                    dataLayer.push(trackingData);
                }
            }

            pendingRequest = fetch(url, {
                method: method,
                headers: {
                    'pragma' : 'no-cache',
                    'cache-control': 'no-cache'
                },
                body: params
            });

            if ($notificationsContainer.length) {
                clearAll({
                    $container: $notificationsContainer
                });
            }

            showNotification(pendingRequest, {
                $container: $notificationsContainer
            });

            responseTracking(pendingRequest);

            pendingRequest.then((res) => {
                return res.clone().json();
            }).then((res) => {
                if (res.success) {
                    // the response can have different contents
                    // if modalContent is present, it will be put into the modal in data-ajax-check-modal and shown
                    if (res.modalContent) {
                        let $modal = modals[res.modalSize || 'md'];

                        if ($modal.length) {
                            $clickedSubmitButton.attr('disabled', false);
                            let $modalContent = $modal.find('.js-ajax-modal-content');
                            $modalContent.html(res.modalContent);
                            initModulesInScope($modalContent);

                            /* start recaptcha widget */
                            let recaptcha = $modalContent.find(".js-ajaxmodal-friendly-recaptcha__captcha");
                            let recaptchaWidget = document.querySelector(".js-ajaxmodal-friendly-recaptcha__captcha");
                            if(recaptcha.length) {
                                let $modalFormSubmitBtn = $modalContent.find(".btn:submit");
                                $modalFormSubmitBtn.attr('disabled','disabled');
                                let ajaxRecaptchaWidget = new friendlyChallenge.WidgetInstance(recaptchaWidget, {/* options */});

                                window.ajaxmodalDoneCallback = ajaxmodalDoneCallback;
                                function ajaxmodalDoneCallback(solution) {
                                    $modalFormSubmitBtn.removeAttr('disabled');
                                }
                            }

                            // close other modals
                            let $openModal = $form.closest('.modal.show');

                            if ($openModal.length) {
                                // if a modal has to be closed, open the new modal after is has been closed -
                                // otherwise there will be scrolling problems (modal-open class on body not set correctly)
                                $openModal.one('hidden.bs.modal', () =>  $modal.modal('show'));

                                $openModal.modal('hide');
                            } else {
                                // if there's no open modal yet, just open the new one
                                $modal.modal('show');
                            }
                            // if a modalDismissedURL is defined, the user will be redirected after closing the modal
                            if (res.modalDismissedURL) {
                                $modal.one('hide.bs.modal', () =>  {window.location.href = res.modalDismissedURL});
                            }
                        }
                        // hide loading-indicator here, so paymentUrl loading looks right
                        $loading.attr('hidden', true);
                    } else if (res.redirectURL) {
                        // ... or redirect to another URL (this is the case when the address is already correct)
                        window.location.href = res.redirectURL;
                    } else if (res.paymentUrl && res.paymentScriptUrl) {
                        $loading.attr('hidden', false);
                        loadScript(res.paymentScriptUrl).then(() => {
                            $loading.attr('hidden', true);

                            // disable button so the response can't be sent twice - doesn't have to be enabled again (after the modal will always be a reload)
                            $('.js-payment-submit').prop('disabled', true);
                            WPP.embeddedPayUrl(res.paymentUrl);
                        }).catch((error) => {
                                //todo: correct error handling - show notification?
                                $('.js-payment-submit').prop('disabled', false);
                                $loading.attr('hidden', true);
                                console.error(error);
                            }
                        );
                    } else {
                        $loading.attr('hidden', true);
                        if ($clickedSubmitButton && $clickedSubmitButton.length) {
                            $clickedSubmitButton.removeAttr('disabled');
                        }
                    }

                    // res.disableInputs contains the selector for the element(s) in which all inputs should be disabled
                    if (res.disableInputs) {
                        $(res.disableInputs).find(':input').prop('disabled', true);
                        // uncheck radio, because disabling it does not prevent from another submission
                        $(res.disableInputs).find(':radio').prop('checked', false);
                    }
                } else {
                    $loading.attr('hidden', true);
                    if ($clickedSubmitButton && $clickedSubmitButton.length) {
                        $clickedSubmitButton.removeAttr('disabled');
                    }
                }
            }).catch((error) => {
                if (error.name !== 'AbortError') {
                    /*Do error stuff*/
                    $loading.attr('hidden', true);
                    if ($clickedSubmitButton && $clickedSubmitButton.length) {
                        $clickedSubmitButton.removeAttr('disabled');
                    }
                    console.error(error);
                }
            });

        }
    });
}

