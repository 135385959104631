import {apiLoaded} from "@elements/ajax-form-map/ajax-form-google";
import {createAjaxForm} from "@elements/ajax-form";
import initMap, {
    setActiveClusterStyle,
    setActiveMarkerStyle,
    setDefaultClusterStyle,
    setDefaultMarkerStyle,
    setMarker
} from "@elements/google-map";
import {showNotification} from "@elements/alert-notification";
import {translate} from '@elements/translations';
import formDataEntries from 'form-data-entries';
import fetch from '@elements/fetch';

let lastActiveMarker;

export function initInScope($scope) {
    if (_config.isB2e) {
        initDsgvoMap(true);
        initMapItems($scope);
    } else {
        let checkCookie = setInterval(function(){
            if (window.UC_UI && window.UC_UI.isInitialized()) {
                $scope.find('.js-dsgvo-map-hint__btn').on('click', function (evt) {
                    $scope.find('[data-cookie-type="maps"]').remove();
                    UC_UI.acceptService("S1pcEj_jZX").then(() => {
                        $(document.body).trigger('ajaxFormMap/accepted');
                    });
                });

                let mapsAccepted = UC_UI.getServicesBaseInfo().find(data => (data.id === "S1pcEj_jZX")).consent.status;
                if(mapsAccepted) {
                    $scope.find('[data-cookie-type="maps"]').remove();
                    initDsgvoMap(true);
                    initMapItems($scope);
                } else {
                    initDsgvoMap(false);
                    $scope.find('[data-cookie-type="maps"]').show();
                }
                clearInterval(checkCookie);
            }
        }, 200);
    }
    function initDsgvoMap(cookieAccepted){
        let $maps = $scope.find('.js-ajax-form-map__map');
        if ($maps && $maps.length) {
            $maps.each(function (index, element) {
                let $container = $(element).closest('.js-ajax-form-map');
                let $mapCanvas = $(element).find('.js-ajax-form-map__map__canvas');
                let $form = $container.find('.js-ajax-form-map__form');
                //debugger;
                let formObj = {
                    //mapObj: initMap(options),
                    element: $form,
                    formApi: createAjaxForm($container, selectors, {
                        onSubmit: x => fetchPois()
                    })
                };

                if(cookieAccepted) {
                    initializeMap().then(function () {
                        fetchPois();
                    });
                }

                $(document.body).on('ajaxFormMap/accepted',function (){
                    initializeMap().then(function () {
                        fetchPois();
                    });
                });

                function initializeMap() {
                    return apiLoaded().then(function () {
                        const poiStyles = {
                            default: {
                                default: {
                                    url: '/static/build/img/map/poi-dealer.svg',
                                    size: new google.maps.Size(40, 55),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(20, 47)
                                },
                                active: {
                                    url: '/static/build/img/map/poi-dealer-active.svg',
                                    size: new google.maps.Size(40, 55),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(20, 47)
                                }
                            },
                            outlet: {
                                default: {
                                    url: '/static/build/img/map/poi-outlet.svg',
                                    size: new google.maps.Size(40, 55),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(20, 47)
                                },
                                active: {
                                    url: '/static/build/img/map/poi-outlet-active.svg',
                                    size: new google.maps.Size(40, 55),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(20, 47)
                                }
                            },
                            topseller: {
                                default: {
                                    url: '/static/build/img/map/poi-topseller.svg',
                                    size: new google.maps.Size(40, 55),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(20, 47)
                                },
                                active: {
                                    url: '/static/build/img/map/poi-topseller-active.svg',
                                    size: new google.maps.Size(40, 55),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(20, 47)
                                }
                            },
                            dhl: {
                                default: {
                                    url: '/static/build/img/map/poi-dhl.svg',
                                    size: new google.maps.Size(40, 55),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(20, 47)
                                },
                                active: {
                                    url: '/static/build/img/map/poi-dhl-active.svg',
                                    size: new google.maps.Size(40, 55),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(20, 47)
                                }
                            },
                        };

                        let clusteringOptions = {
                            default: {
                                styles: [{
                                    height: 46,
                                    url: "/static/build/img/map/marker-cluster.svg",
                                    width: 46,
                                    textSize: 20,
                                    textColor: "#FFF"
                                }]
                            },
                            active: {
                                styles: [{
                                    height: 46,
                                    url: "/static/build/img/map/marker-cluster-active.svg",
                                    width: 46,
                                    textSize: 20,
                                    textColor: "#FFF"
                                }]
                            }
                        };

                        let options = $(element).data('google-map-options');

                        options = {
                            scrollwheel: null,
                            gestureHandling: 'cooperative',
                            mapTypeControl: false,
                            streetViewControl: false,
                            fullscreenControl: false,
                            ...options
                        };

                        //init ajax form map
                        formObj.mapObj = initMap({
                            element: $mapCanvas[0],
                            mapOptions: options,
                            poiStyles,
                            clustering: false,
                            clusteringOptions,
                            onActivateMarker: (marker, mapObj) => onActivateMarker($container, marker, mapObj),
                            onDeactivateMarker: (marker, mapObj) => onDeactivateMarker($container, marker, mapObj)
                        });

                        $container.find('.js-ajax-form-map__get-geo-location').on('click', function (e) {
                            e.preventDefault();
                            getLocation(formObj.mapObj, $container);
                        });

                        $container.on('submit.ajax-form', function () {
                            lastActiveMarker = null;
                        });

                        // check clusters after zooming, if they contain the active marker
                        if (formObj.mapObj.clusteringPromise) {
                            formObj.mapObj.clusteringPromise.then((markerClusterer) => {
                                formObj.mapObj.map.addListener('idle', function () {

                                    if (lastActiveMarker) {
                                        // get cluster that contains the active marker and set it to active
                                        let activeCluster = markerClusterer.getMarkersCluster(lastActiveMarker);
                                        if (activeCluster) {
                                            // only set active, if it is not already active to prevent flashing when map is panned (idle event fires after zooming AND panning)
                                            if (activeCluster.clusterIcon_.styles_[0].url !== clusteringOptions.active.styles[0].url ) {
                                                setActiveClusterStyle(activeCluster, formObj.mapObj);
                                            }
                                        }
                                    }
                                });
                            });
                        }

                    });
                }

                function fetchPois() {

                    if(!(formObj.mapObj)) {
                        return
                    }

                    fetch($container.data('form-map-url'), {
                        method: 'GET',
                        headers: {
                            ajax: 1 // add ajax header to differentiate between and ajax requests and page request.
                            // If this header is not set, browser back could lead the browser to use the cached result of this request as page response.
                        },
                        body: new URLSearchParams(formDataEntries($form[0]))
                    }).then((res) => {
                        return res.json()
                    }).then((res) => {
                        if (res.success) {
                            setMarker(formObj.mapObj, res.pois);
                        }
                    }).catch((error) => {
                        console.error("loading data-form-map-url failed:" + error);
                    });
                }

            });
        }

    }
}


function onActivateMarker($container, marker, mapObj) {
    if (marker.dataId) {
        if (lastActiveMarker) {
            setDefaultMarkerStyle(lastActiveMarker, mapObj);
        }

        lastActiveMarker = marker;
        let $allItems = $container.find('.js-ajax-form-map__item');
        let $item = $container.find(`.js-ajax-form-map__item[data-id="${marker.dataId}"]`);

        if ($item && $item.length) {
            mapObj.map.setZoom(19);

            $allItems.removeClass('is-active');
            $item.addClass('is-active');

            if (matchMedia('(min-width: 768px)').matches) {
                $item.find('.collapse').collapse('show');
                let itemOffset = $item[0].offsetTop;
                $('.js-ajax-form-map__list').stop().animate({
                    scrollTop: itemOffset
                }, 360);
            } else {
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                let itemTop = scrollTop + $item[0].getBoundingClientRect().top;
                setTimeout(function(){
                    $('.modal.show').stop().animate({
                        scrollTop: itemTop - 500
                    }, 360);
                }, 750);
            }
        }
    }
}

function onDeactivateMarker($container, marker, mapObj) {
    // closeInfoBoxByMarker(marker, mapObj);
    if (marker.dataId) {
        let $item = $container.find(`.js-ajax-form-map__item[data-id="${marker.dataId}"]`);
        $item.removeClass('is-active');
        // $item.find('.collapse').collapse('hide');
    }
}

function initMapItems($container) {
    $container.find('.js-ajax-form-map__item').each(function () {
        let $item = $(this);

        $item.on('click', function () {
            let id = $(this).data('id');
            let $canvas = $(this).closest('.js-ajax-form-map').find('.js-ajax-form-map__map__canvas');
            let mapObj = $canvas.data('google-map');
            let activeMarker = mapObj.marker.filter(marker =>
                !!marker.dataId && marker.dataId.toString() === id.toString())[0];

            if (lastActiveMarker) {
                setDefaultMarkerStyle(lastActiveMarker, mapObj);
            }

            if (activeMarker) {
                lastActiveMarker = activeMarker;
                setActiveMarkerStyle(activeMarker, mapObj);
                mapObj.map.panTo(activeMarker.getPosition());

                $container.find('.js-ajax-form-map__item').removeClass('is-active');
                $item.addClass('is-active');

                mapObj.map.setZoom(19);

                if (mapObj.clusteringPromise) {
                    mapObj.clusteringPromise.then((markerClusterer) => {

                        // reset all clusters to default style
                        let clusters = markerClusterer.getClusters();
                        clusters.forEach(cluster => setDefaultClusterStyle(cluster, mapObj));

                        // get cluster that contains the active marker and set it to active
                        let activeCluster = markerClusterer.getMarkersCluster(activeMarker);
                        setActiveClusterStyle(activeCluster, mapObj);
                    });
                }
            }
        });
    });
}

function getLocation(mapObj, $container) {
    if (navigator.geolocation) {

        navigator.geolocation.getCurrentPosition(function (position) {
            let latitude = position.coords.latitude;
            let longitude = position.coords.longitude;

            if (latitude && longitude) {
                // if (ownLocationMarker) {
                //     ownLocationMarker.setMap(null);
                // }

                let pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

                // $container.find('.js-ajax-form-map__lat').val(position.coords.latitude);
                // $container.find('.js-ajax-form-map__lng').val(position.coords.longitude);

                // change text in search field
                let $searchInput = $container.find('.js-ajax-form-map__search-input');
                let geocoder = new google.maps.Geocoder();

                geocoder.geocode({location: pos}, (results, status) => {
                    if (status === "OK") {
                        if (results[0]) {
                            $searchInput.val(results[0].formatted_address);
                            // submit form
                            $container.data('ajax-form').submit();
                        } else {
                            console.log("Geocoding: No results found");
                        }
                    } else {
                        console.log("Geocoder failed due to: " + status);
                    }
                });

                // $searchInput.val(translate('storelocator.my-location'));
                // $searchInput.addClass('text-light-blue');


                // add marker
                // ownLocationMarker = new google.maps.Marker({
                //     map: mapObj.map,
                //     position: pos,
                //     // icon: poiStyle ? poiStyle.default : null,
                //     poiStyle: {url: '/static/build/img/map/poi-dealer.svg',
                //         size: new google.maps.Size(40, 55),
                //         origin: new google.maps.Point(0, 0),
                //         anchor: new google.maps.Point(20, 47)}
                // });
                // addMarker(mapObj, )


            }
        }, function (e) {
            console.log(e);
            showNotification({
                type: 'danger',
                title: translate('storelocator.errors.no-location.title'),
                content: translate('storelocator.errors.no-location.message'),
                closable: true
            });
        });
    } else {
        // the browser doesn't support geolocation
        showNotification({
            type: 'danger',
            title: translate('storelocator.errors.not-supported.title'),
            content: translate('storelocator.errors.not-supported.message'),
            closable: true
        });
    }

}


const selectors = {
    base: '.js-ajax-form-map',
    result: '.js-ajax-form-map__result',
    loading: '.js-ajax-form-map__loading',
    notifications: '.js-ajax-form-map__notifications',
    form: '.js-ajax-form-map__form',
    additionalForm: '.js-ajax-form-map__additional-form',
    errorArea: '.js-ajax-form-map__error-area',
    retry: '.js-ajax-form-map__retry',
    link: '.js-ajax-form-map__link'
};